import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import React from "react";
import { ButtonClick } from "@types-custom";
import { Control } from "react-hook-form";
import { Value } from "react-phone-number-input";
import { useStyles } from "./useStyles";

export interface InputTelUiProps {
  id: string;
  name?: string;
  rules?: object;
  placeholder?: string;
  control: Control<any>;
  onChange?: (value?: Value) => void;
  onFocus?: (value?: Value) => void;
  onBlur?: (value?: Value) => void;
  isValid?: boolean;
  disabled?: boolean;
  hasViewPassword?: boolean;
  hasTooltip?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  minValue?: number;
  maxValue?: number;
  tooltipMessage?: string;
  handleViewPasswordClick?: ButtonClick;
  handleTooltipClick?: ButtonClick;
}

export const InputTelUi = (props: InputTelUiProps) => {
  const {
    classes,
    cx,
  } = useStyles();

  return <>
    <div className={classes.inputWrapper}>
      <PhoneInputWithCountry
        tabIndex={props.tabIndex}
        defaultCountry={"IT"}
        name={props.name ? props.name : props.id}
        rules={props.rules}
        placeholder={props.placeholder}
        control={props.control}
        {...(props.onFocus ? { onChange: props.onFocus } : {})}
        {...(props.onBlur ? { onChange: props.onBlur } : {})}
        {...(props.onChange ? { onChange: props.onChange } : {})}
        className={cx({
          [classes.inputRoot]: true,
          [classes.isInvalid]: props.isValid === false,
        })}
      />
    </div>
  </>;
};
