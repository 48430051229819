import { ChartOptions } from "chart.js";
import { RangeMinMax } from "@common/model/Range";
import { Scatter } from "react-chartjs-2";
import { Serie } from "@common/model/Serie";
import { Trans } from "@lingui/macro";
import {
  convertPhasesToPoints,
  formatToDistance,
  formatToTime,
  getMaxXByPoints,
  getMaxYByRange,
  getMinXByPoints,
  getMinYByRange,
  getTotalMaxXByPoints,
} from "@feature/chart/service/chartSerivice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { useAppSelector } from "@core/redux/store";
import { useRunHalfHeight } from "@feature/run/hook/useRunHalfHeight";
import { useStyles } from "@theme/makeStyles";

type Props = {
  serie: Serie;
  rangeFull?: RangeMinMax;
  range: RangeMinMax;
  minPower?: number;
  maxPower?: number;
  minSpeed?: number;
  maxSpeed?: number;
}

export const ChartEccentricConcentricLiveComponent = (props: Props) => {
  const settingState = useAppSelector(selectSettingState);

  const simplified = props.serie.simplifiedPoints;
  let minY = props.rangeFull ? getMinYByRange(props.rangeFull) : 0;
  minY = Math.floor(minY / 100) * 100;
  let maxY = props.rangeFull ? getMaxYByRange(props.rangeFull) : 4000;
  maxY = Math.ceil(maxY / 100) * 100;

  const minPower = props.minPower ?? 0;
  const maxPower = props.maxPower ?? 0;
  const minSpeed = props.minSpeed ?? 0;
  const maxSpeed = props.maxSpeed ?? 0;

  const { theme } = useStyles();

  const maxTotalX = getTotalMaxXByPoints(simplified);

  const minX = getMinXByPoints(simplified);
  const maxX = getMaxXByPoints(simplified);

  const options: ChartOptions<"scatter"> = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: minY,
        max: maxY,
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: "rgba(255,255,255,0.2)",
          tickBorderDash: [
            5,
            5,
          ],
          lineWidth: 1,
        },
        ticks: {
          stepSize: 100,
          callback: formatToDistance,
        },
      },
      x: {
        min: minX,
        max: maxX,
        ticks: {
          stepSize: 1000,
          callback: formatToTime,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      zoom: {
        zoom: {
          wheel: { enabled: false },
          pinch: { enabled: false },
        },
        pan: { enabled: false },
      },
      annotation: {
        annotations: {
          rangeMin: {
            display: props.rangeFull && props.rangeFull.min > 0,
            type: "line",
            yMin: props.rangeFull?.min,
            yMax: props.rangeFull?.min,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
          rangeMax: {
            display: props.rangeFull && props.rangeFull.max > 0,
            type: "line",
            yMin: props.rangeFull?.max,
            yMax: props.rangeFull?.max,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
        },
      },
    },
  };

  const originalPoints = props.serie.originalPoints;
  const phases = props.serie.finalPhases;

  const data = {
    datasets: [
      ...settingState.isShowMovementDetails ? convertPhasesToPoints(originalPoints, simplified, phases, minPower, maxPower, minSpeed, maxSpeed) : [],
      {
        label: "Movement elaborated",
        pointRadius: 0,
        borderColor: settingState.isShowMovementDetails ? theme.colors.neonTransparent : theme.colors.neon,
        lineTension: 0.1,
        showLine: true,
        data: simplified,
      },
    ],
  };

  const { runHalfHeight } = useRunHalfHeight();

  return <>
    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Position</Trans>
    </h4>

    <div style={{ height: `${ runHalfHeight }px` }}>
      <Scatter
        redraw={false}
        data={data}
        options={options}
      />
    </div>
  </>;
};
