export const STORE_ACCOUNT_KEY = "account";
export const API_CACHE_TAG_ACCOUNT = "account";

export const STORE_FORM_ACCOUNT = "FormUi.account";
export const STORE_FORM_ACCOUNT_UPDATE = "FormUi.accountUpdate";

export const PREFERENCE_KEY_ACCOUNT_TOKEN = "accountToken";
export const PREFERENCE_KEY_ACCOUNT_REFRESH_TOKEN = "accountRefreshToken";
export const PREFERENCE_KEY_ACCOUNT_EXPIRES_AT = "accountExpiresAt";

export const LINK_SHOP = "https://www.bodygon.com";
export const LINK_PRIVACY_POLICY = "https://www.bodygon.com/policies/privacy-policy";
export const LINK_TERMS_AND_CONDITIONS = "https://www.bodygon.com/policies/terms-of-service";
export const LINK_PRECTICAL_TEST_TERMS_AND_CONDITIONS_RIMINI_WELNESS_2024 = "https://www.bodygon.com/pages/practical-test-conditions-rimini-wellness-2024";
export const LINK_MARKETING = "https://www.bodygon.com/pages/marketing-terms";
