import {
  CHART_X_LENGTH,
  CHART_X_LENGTH_PADDING,
} from "@feature/chart/chartConstants";
import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
  RANGE_EXTRA_TOLLERANCE_FOR_VISIBILITY_MM,
} from "@common/service/constants";
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
} from "@common/model/Direction";
import {
  PHASES_IGNORE_REASON_VISIBLE,
  Phase,
} from "@common/model/Phase";
import { Point } from "@common/model/Point";
import { RangeMinMax } from "@common/model/Range";
import { theme } from "@theme/index";

export const roundAxisNumber = (label: string| number): number => {
  if (typeof label == "number") {
    return Math.floor(label);
  }
  return Math.floor(parseInt(label, 10));
};

export const formatToTime = (value: string| number): string => {
  if (value <= 1 || value === "0") {
    return "0";
  }
  if (typeof value == "number") {
    if (value % 500 === 500) {
      return `${ Math.floor(value / 1000) } s`;
    }
    return `${ Math.floor(value / 1000) } s`;
  }
  return `${ Math.floor(parseInt(value, 10) / 1000) } s`;
};

export const formatToDistance = (value: string| number): string => {
  if (typeof value == "number") {
    if (value === 0) {
      return "0";
    }
    return `${ Math.floor(value / 10) } cm`;
  }
  return `${ Math.floor(parseInt(value, 10) / 100) } cm`;
};

export const formatToPower = (value: string | number): string => {
  if (typeof value == "number") {
    if (value === 0) {
      return "0";
    }
    return `${ Math.floor(value) } W`;
  }
  return `${ Math.floor(parseInt(value, 10)) } W`;
};

export const formatToSpeed = (fixedValues?: number[]): (value: string | number) => string | null => {
  return (value: string | number): string | null => {
    if (fixedValues && !fixedValues.includes(value as number)) {
      return null;
    }
    if (typeof value == "number") {
      if (value === 0) {
        return "0";
      }
      return `${ Math.floor(value) } cm/s`;
    }
    return `${ Math.floor(parseInt(value, 10)) } cm/s`;
  };
};

export const getMinXByPoints = (points: Point[]): number => {
  const pointsLenght = points.length;
  if (!pointsLenght) {
    return 0;
  }
  const time = points[pointsLenght - 1].x;

  const value = Math.max(0, time - CHART_X_LENGTH + CHART_X_LENGTH_PADDING);
  const rounded = Math.round(value / 1000) * 1000;
  return rounded;
};

export const getMaxXByPoints = (points: Point[]): number => {
  const pointsLenght = points.length;
  if (!pointsLenght) {
    return 0;
  }
  const valueX = points[pointsLenght - 1].x;
  if (valueX < 7000) {
    return CHART_X_LENGTH;
  }
  const value = Math.max(CHART_X_LENGTH, valueX);
  const rounded = (Math.round((value) / 1000) * 1000) + 1000;
  return rounded;
};

export const getTotalMaxXByPoints = (points: Point[]): number => {
  const pointsLenght = points.length;
  if (!pointsLenght) {
    return 0;
  }
  return points[pointsLenght - 1].x + CHART_X_LENGTH_PADDING;
};

export const getMinYByPoints = (points: Point[]): number => {
  const pointsLenght = points.length;
  if (!pointsLenght) {
    return 0;
  }
  let min = 99999;
  for (let i = 0; i < points.length; i++) {
    min = Math.min(min, points[i].y);
  }
  return Math.max(DEVICE_RANGE_MIN, min - 50);
};

export const getMaxYByPoints = (points: Point[]): number => {
  const pointsLenght = points.length;
  if (!pointsLenght) {
    return DEVICE_RANGE_MAX;
  }
  let max = 0;
  for (let i = 0; i < points.length; i++) {
    max = Math.max(max, points[i].y);
  }
  if (max === 0) {
    return DEVICE_RANGE_MAX;
  }
  return Math.min(DEVICE_RANGE_MAX, max + 50);
};

export const getMinYByRange = (rangeMinMax: RangeMinMax): number => {
  return Math.max(DEVICE_RANGE_MIN, rangeMinMax.min - RANGE_EXTRA_TOLLERANCE_FOR_VISIBILITY_MM);
};

export const getMaxYByRange = (rangeMinMax: RangeMinMax): number => {
  return Math.min(DEVICE_RANGE_MAX, rangeMinMax.max + RANGE_EXTRA_TOLLERANCE_FOR_VISIBILITY_MM);
};

export const convertOriginalPhasesToPoints = (
  originalPoints: Point[],
  simplifiedPoints: Point[],
  allPhases: Phase[]
): any[] => {
  const phasesPoint: any[] = [];
  const ascendingPhases = allPhases.filter(phase => phase.direction === DIRECTION_ASCENDING);

  ascendingPhases.forEach((phase, index) => {
    const fromIndex = phase.fromIndex;
    const toIndex = phase.toIndex;
    const pointsFromRefined = originalPoints.slice(fromIndex, toIndex + 1);
    const points: Point[] = simplifiedPoints.filter(semplifiedPoint => pointsFromRefined.find(point => point.x === semplifiedPoint.x));

    phasesPoint.push({
      label: `OPhase ${ index + 1 }`,
      pointRadius: 1,
      backgroundColor: "rgba(255,0,0,0.3)",
      borderColor: "rgba(255,0,0,0.3)",
      fill: true,
      lineTension: 0.1,
      showLine: true,
      hidden: false,
      data: points,
    });
  });

  return phasesPoint;
};

export const convertMergedPhasesToPoints = (
  originalPoints: Point[],
  simplifiedPoints: Point[],
  allPhases: Phase[]
): any[] => {
  const phasesPoint: any[] = [];
  const ascendingPhases = allPhases.filter(phase => phase.direction === DIRECTION_ASCENDING);

  ascendingPhases.forEach((phase, index) => {
    const fromIndex = phase.fromIndex;
    const toIndex = phase.toIndex;
    const pointsFromRefined = originalPoints.slice(fromIndex, toIndex + 1);
    const points: Point[] = simplifiedPoints.filter(semplifiedPoint => pointsFromRefined.find(point => point.x === semplifiedPoint.x));

    phasesPoint.push({
      label: `MPhase ${ index + 1 }`,
      pointRadius: 1,
      backgroundColor: "rgba(0,255,0,0.3)",
      borderColor: "rgba(0,255,0,0.3)",
      fill: true,
      lineTension: 0.1,
      showLine: true,
      hidden: false,
      data: points,
    });
  });

  return phasesPoint;
};

export const getColorByPhaseAndPower = (
  phase: Phase,
  transparent: boolean,
  minPower?: number,
  maxPower?: number,
  minSpeed?: number,
  maxSpeed?: number
): string => {
  if (phase.ignoreReason) {
    switch (phase.ignoreReason) {
      case "MANUAL":
        return transparent ? theme.colors.greyLightTransparent : theme.colors.greyLight;
      default:
        return transparent ? theme.colors.greyTransparent : theme.colors.grey;
    }
  }
  if (maxSpeed && phase.physics.speed > maxSpeed) {
    return transparent ? theme.colors.orangeTransparent : theme.colors.orange;
  }
  if (minSpeed && phase.physics.speed < minSpeed) {
    return transparent ? theme.colors.yellowTransparent : theme.colors.yellow;
  }
  if (maxPower && phase.physics.power > maxPower) {
    return transparent ? theme.colors.yellowTransparent : theme.colors.yellow;
  }
  if (minPower && phase.physics.power < minPower) {
    return transparent ? theme.colors.orangeTransparent : theme.colors.orange;
  }
  return transparent ? theme.colors.neonTransparent : theme.colors.neon;
};

export const getColorByPhaseAndSpeed = (
  phase: Phase,
  transparent: boolean,
  minSpeed?: number,
  maxSpeed?: number
): string => {
  if (phase.ignoreReason) {
    switch (phase.ignoreReason) {
      case "MANUAL":
        return transparent ? theme.colors.greyLightTransparent : theme.colors.greyLight;
      default:
        return transparent ? theme.colors.greyTransparent : theme.colors.grey;
    }
  }
  if (maxSpeed && phase.physics.speed > maxSpeed / 100) {
    return transparent ? theme.colors.orangeTransparent : theme.colors.orange;
  }
  if (minSpeed && phase.physics.speed >= minSpeed / 100) {
    return transparent ? theme.colors.neonTransparent : theme.colors.neon;
  }
  return transparent ? theme.colors.yellowTransparent : theme.colors.yellow;
};

export const convertPhasesToPoints = (
  originalPoints: Point[],
  simplifiedPoints: Point[],
  allPhases: Phase[],
  minPower?: number,
  maxPower?: number,
  minSpeed?: number,
  maxSpeed?: number
): any[] => {
  const phasesPoint: any[] = [];
  const ascendingPhases = allPhases.filter(phase =>
    (
      phase.direction === DIRECTION_ASCENDING ||
      phase.direction === DIRECTION_DESCENDING
    ) &&
    phase.positions.isAllInRange &&
    (
      !phase.ignoreReason ||
      PHASES_IGNORE_REASON_VISIBLE.includes(phase.ignoreReason)
    )
  );

  ascendingPhases.forEach((phase, index) => {
    const fromIndex = phase.fromIndex;
    const toIndex = phase.toIndex;
    const pointsFromRefined = originalPoints.slice(fromIndex, toIndex + 1);
    const points: Point[] = simplifiedPoints.filter(semplifiedPoint => pointsFromRefined.find(point => point.x === semplifiedPoint.x));
    const color = getColorByPhaseAndPower(phase, false, minPower, maxPower, minSpeed, maxSpeed);

    phasesPoint.push({
      label: `Phase ${ index + 1 }`,
      pointRadius: 0,
      borderColor: color,
      backgroundColor: color,
      fill: false,
      lineTension: 0.1,
      showLine: true,
      hidden: false,
      data: points,
    });
  });

  return phasesPoint;
};
