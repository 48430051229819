import { makeStyles } from "@theme/makeStyles";

export const useStyles = makeStyles()(
  (theme, props, classes) => ({
    // Base styles
    inputWrapper: {
      zIndex: 1,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxHeight: 50,
    },
    tooltipVisible: {
      width: "calc(100% - 66px)",
      zIndex: 20,
      visibility: "visible",
    },
    inputRoot: {
      ...theme.typography.RR16,
      color: theme.colors.pureWhite,
      position: "relative",
      WebkitAppearance: "none",
      border: "none",
      width: "100%",
      minHeight: 50,
      borderRadius: 16,
      backgroundColor: theme.colors.input,
      padding: 16,
      ...theme.typography.RR14,
      "&::placeholder": { ...theme.typography.RR14 },
      "&:focus": { outline: "none" },
      "&>input": {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
      },
    },
    isInvalid: { outline: `1px solid ${ theme.colors.red }` },
  })
);
