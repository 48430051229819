import {
  useRef,
  useState,
} from "react";

export const useLogic = () => {
  const $tooltip = useRef(null);
  const [
    isPasswordVisible,
    setPasswordVisible,
  ] = useState(false);
  const [
    isTooltipVisible,
    setTooltipVisible,
  ] = useState(false);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleTooltipVisibility = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  return {
    $tooltip: $tooltip,
    handlePasswordVisibility: handlePasswordVisibility,
    handleTooltipVisibility: handleTooltipVisibility,
    isTooltipVisible: isTooltipVisible,
    isPasswordVisible: isPasswordVisible,
  };
};
