import React, {
  ForwardedRef,
  KeyboardEvent,
} from "react";
import {
  AutoComplete,
  ButtonClick,
  InputTypes,
} from "@types-custom";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  ChangeEvent,
  FocusEventHandler,
} from "react";
import { TooltipUi } from "@ui/TooltipUi/TooltipUi";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

export interface InputUiProps {
  id: string;
  name?: string;
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
  pattern?: string;
  type: InputTypes;
  placeholder?: string;
  value?: string;
  autoComplete: AutoComplete;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  isValid?: boolean;
  disabled?: boolean;
  hasViewPassword?: boolean;
  hasTooltip?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  minValue?: number;
  maxValue?: number;
  tooltipMessage?: string;
  handleViewPasswordClick?: ButtonClick;
  handleTooltipClick?: ButtonClick;
}

export const InputUi = React.forwardRef((
  props: InputUiProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  // Separation of concerns
  const {
    $tooltip,
    handlePasswordVisibility,
    handleTooltipVisibility,
    isTooltipVisible,
    isPasswordVisible,
  } = useLogic();

  const {
    classes, cx,
  } = useStyles();

  const inputType = props.type === "password" && isPasswordVisible ? "text" : props.type;

  return <>
    <div className={classes.inputWrapper}>
      <input
        ref={ref}
        className={cx({
          [classes.inputRoot]: true,
          [classes.isInvalid]: props.isValid === false,
        })}
        id={props.id}
        disabled={props.disabled}
        inputMode={props.inputMode ?? "text"}
        pattern={props.pattern ?? ""}
        name={props.name ? props.name : props.id}
        type={inputType}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}
        tabIndex={props.tabIndex}
        autoFocus={props.autoFocus}
        min={props.minValue}
        max={props.maxValue}
      />

      {
        props.type === "password" &&
        <div className={classes.passwordUtilitiesButtons}>
          {
            props.hasViewPassword === true &&
            <ButtonUi
              label=""
              width="fit-content"
              type="primary"
              labelColor="neon"
              backgroundColor="transparent"
              icon="view-hide"
              iconColor="grey"
              iconPosition="left"
              fontStyle="RB12"
              appearance="rounded"
              hasToggle={true}
              hasPressed={true}
              onClick={handlePasswordVisibility}
              isActive={false}
            />
          }
          {
            props.hasTooltip === true &&
            <ButtonUi
              label=""
              width="fit-content"
              type="primary"
              labelColor="neon"
              backgroundColor="transparent"
              icon="info"
              iconColor="grey"
              iconPosition="left"
              fontStyle="RB12"
              appearance="rounded"
              hasToggle={true}
              hasPressed={true}
              onClick={handleTooltipVisibility}
              isActive={false}
            />
          }
        </div>
      }
      {
        props.hasTooltip && props.tooltipMessage !== undefined &&
        <TooltipUi
          ref={$tooltip}
          className={cx({ [classes.tooltipVisible]: isTooltipVisible })}
          message={props.tooltipMessage} />
      }
    </div>
  </>;
});
