const bodygon = {
  neon: "#A8FF00", // rgba(168, 255, 0)
  neonTransparent: "#A8FF0055", // rgba(168, 255, 0)
  neonLight: "#EBF4DA", // rgba(235, 244, 218)
  neonPressed: "#C4FD52", // rgb(196, 253, 82)
  red: "#FF0028", // rgba(255, 0, 40)
  redPressed: "#FD274D",
  redSmorzed: "#730012",
  realBlack: "#000000",
  realBlackTrasparent: "#00000055",
  black: "#282727", // rgba(40, 39, 39)
  blackPressed: "#302F2F",
  input: "#141313",
  darkTint: "#181717",
  darkTintPressed: "#1D1C1C",
  orange: "#FF9500", // rgba(255, 149, 0)
  orangeTransparent: "#FF950055", // rgba(255, 149, 0)
  yellow: "#FFDA00",
  yellowTransparent: "#FFDA0055",
  grey: "#757575",
  greyTransparent: "#75757555",
  greyLight: "#F4F4F4",
  greyLightTransparent: "#F4F4F455",
  purple: "#6327D8",
  darkPurple: "#2738C1",
  cyan: "#27D8BC",
  separatorDarkBackground: "rgba(255,255,255, 0.1)",
  separatorColoredBackground: "rgba(0,0,0, 0.1)",
  transparent: "transparent",
};

const defaults = {
  pureBlack: "#000000", // Pure Black
  pureBlackPressed: bodygon.darkTintPressed,
  pureWhite: "#ffffff", // Pure White
  pureWhiteTransparent: "#ffffff55", // Pure White transparent
};

export const colors = {
  ...defaults,
  ...bodygon,
};
