import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CHART_ZOOM_MIN_X,
  CHART_ZOOM_MIN_Y,
} from "@feature/chart/chartConstants";
import {
  Chart,
  ChartOptions,
} from "chart.js";
import { ChartControllerComponenet } from "@feature/chart/component/ChartControllerComponent";
import {
  DEVICE_RANGE_MAX,
} from "@common/service/constants";
import { Phase } from "@common/model/Phase";
import { Point } from "@common/model/Point";
import { RangeMinMax } from "@common/model/Range";
import { Scatter } from "react-chartjs-2";
import { Trans } from "@lingui/macro";
import {
  convertPhasesToPoints,
  formatToDistance,
  formatToTime,
  getMaxYByPoints,
  getMaxYByRange,
  getMinYByPoints,
  getMinYByRange,
  getTotalMaxXByPoints,
} from "@feature/chart/service/chartSerivice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { useAppSelector } from "@core/redux/store";
import { useRunHalfHeight } from "@feature/run/hook/useRunHalfHeight";
import { useStyles } from "@theme/makeStyles";

type Props = {
  originalPoints: Point[];
  simplifiedPoints: Point[];
  finalPhases: Phase[];
  rangeFull?: RangeMinMax;
  range?: RangeMinMax;
  minPower?: number;
  maxPower?: number;
  minSpeed?: number;
  maxSpeed?: number;
  showController?: boolean;
}

export const ChartEccentricConcentricComponent = (props: Props) => {
  const { theme } = useStyles();
  const settingState = useAppSelector(selectSettingState);

  const simplified = props.simplifiedPoints;
  const hasData = simplified.length > 0;

  const minPower = props.minPower ?? 0;
  const maxPower = props.maxPower ?? 0;
  const minSpeed = props.minSpeed ?? 0;
  const maxSpeed = props.maxSpeed ?? 0;

  const getMinY = () => {
    let min = 99999;

    if (simplified.length > 0) {
      min = Math.min(min, getMinYByPoints(simplified));
    }

    if (props.rangeFull) {
      min = Math.min(min, getMinYByRange(props.rangeFull));
    }

    if (min === 99999) {
      min = 0;
    }

    return min;
  };

  const getMaxY = () => {
    let max = 0;

    if (simplified.length > 0) {
      max = Math.max(max, getMaxYByPoints(simplified));
    }

    if (props.rangeFull) {
      max = Math.max(max, getMaxYByRange(props.rangeFull));
    }

    if (max === 0) {
      max = DEVICE_RANGE_MAX;
    }

    return max;
  };

  const minY = getMinY();
  const maxY = getMaxY();

  const maxTotalX = getTotalMaxXByPoints(simplified);

  const getXTickStepSize = () => {
    if (maxTotalX <= 5000) {
      return 1000;
    } else if (maxTotalX <= 20000) {
      return 2000;
    } else if (maxTotalX <= 40000) {
      return 5000;
    } else if (maxTotalX <= 120000) {
      return 10000;
    }
    return 30000;
  };

  const getYTickStepSize = () => {
    if (maxY === DEVICE_RANGE_MAX) {
      return 500;
    }
    return 100;
  };

  const [
    isAnalyzing,
    setIsAnalyzing,
  ] = useState(false);

  const { runHalfHeight } = useRunHalfHeight();

  const [
    height,
    setHeight,
  ] = useState(runHalfHeight);

  useEffect(() => {
    setHeight(runHalfHeight - 35);
  }, [ runHalfHeight ]);

  const options: ChartOptions<"scatter"> = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: minY,
        max: maxY,
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: "rgba(255,255,255,0.2)",
          tickBorderDash: [
            5,
            5,
          ],
          lineWidth: 1,
        },
        ticks: {
          stepSize: getYTickStepSize(),
          callback: formatToDistance,
        },
      },
      x: {
        ticks: {
          stepSize: getXTickStepSize(),
          callback: formatToTime,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      zoom: {
        zoom: {
          wheel: { enabled: isAnalyzing },
          pinch: { enabled: isAnalyzing },
          mode: "xy",
        },
        pan: {
          enabled: isAnalyzing,
          mode: "xy",
        },
        limits: {
          x: {
            min: 0,
            max: maxTotalX,
            minRange: CHART_ZOOM_MIN_X,
          },
          y: {
            min: minY,
            max: maxY,
            minRange: CHART_ZOOM_MIN_Y,
          },
        },
      },
      annotation: {
        annotations: {
          rangeMin: {
            display: props.rangeFull && props.rangeFull.min > 0,
            type: "line",
            yMin: props.rangeFull?.min,
            yMax: props.rangeFull?.min,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
          rangeMax: {
            display: props.rangeFull && props.rangeFull.max > 0,
            type: "line",
            yMin: props.rangeFull?.max,
            yMax: props.rangeFull?.max,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
        },
      },
    },
  };

  const originalPoints = props.originalPoints;
  const phases = props.finalPhases;

  const data = {
    datasets: [
      ...settingState.isShowMovementDetails ? convertPhasesToPoints(originalPoints, simplified, phases, minPower, maxPower, minSpeed, maxSpeed) : [],
      {
        label: "Movement elaborated",
        pointRadius: 0,
        borderColor: settingState.isShowMovementDetails ? theme.colors.neonTransparent : theme.colors.neon,
        lineTension: 0.1,
        showLine: true,
        data: simplified,
      },
    ],
  };

  const chartRef = useRef<Chart<"scatter">>(null);

  useEffect(() => {
    const chart = chartRef.current;
    // setInterval(() => {
    //   chart.zoom(100000);
    // }, 5000);
  }, []);

  return <>
    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Position</Trans>
      {
        hasData &&
        props.showController &&
			  <ChartControllerComponenet
				  isEnabled={isAnalyzing}
				  isEnabledHandler={setIsAnalyzing}
				  height={height}
				  heightHandler={setHeight}
			  />
      }
    </h4>

    <div style={{ height: `${ height }px` }}>
      <Scatter
        redraw={true}
        data={data}
        options={options}
        ref={chartRef}
      />
    </div>
  </>;
};
