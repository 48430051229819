import {
  CSSProperties,
  ReactNode,
} from "react";

type Props = {
  if: boolean;
  children: ReactNode | ReactNode[];
}

export const VisibleUi = (props: Props) => {
  const style: CSSProperties = {};

  if (!props.if) {
    style.display = "none";
  }

  return <div style={style}>
    { props.children }
  </div>;
};
