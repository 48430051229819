import React, {
  useEffect,
  useState,
} from "react";
import useFormPersist from "react-hook-form-persist";
import { AccountLoginInputType } from "@common/type-graphql/account/input-type/account-login.input-type";
import { AccountLoginOtpInputType } from "@common/type-graphql/account/input-type/account-login-otp.input-type";
import {
  EMAIL_NOT_VALID_ERROR_MESSAGE,
  EMAIL_OR_PASSWORD_ERROR_MESSAGE,
  PASSWORD_FORCE_EXPIRED_ERROR_MESSAGE,
  PHONE_NUMBER_NOT_VALID_ERROR_MESSAGE,
  PLEASE_CONFIRM_OTP_ERROR_MESSAGE,
} from "@common/constants";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputTelUi } from "@ui/InputTelUi/InputUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { OtpModalComponent } from "@feature/account/component/otpModalComponent";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { STORE_FORM_ACCOUNT } from "@feature/account/accountConstants";
import {
  Trans,
  t,
} from "@lingui/macro";
import { VisibleUi } from "@ui/VisibleUi/VisibleUi";
import { config } from "@config";
import {
  emailPattern,
  passwordPattern,
} from "@common/patterns";
import {
  historyController,
  useAppDispatch,
} from "@core/redux/store";
import { i18n } from "@lingui/core";
import { routes } from "@core/route";
import { setActiveRoute } from "@feature/tab/slice/tabsSlice";
import {
  toast,
  toastDoneAll,
} from "@feature/toast/slice/toastSlice";
import { useForm } from "react-hook-form";
import {
  useLazyAccountExistByEmailQuery,
  useLazyAccountExistByPhoneNumberQuery,
  useLoginMutation,
  useLoginOtpMutation,
} from "../../api/accountApi";
import { useStyles } from "@feature/account/style";

export type EmailOrPhoneNumber = "email" | "phoneNumber";

type FormData = {
  email?: string;
  phoneNumber?: string;
  password?: string;
}

type Props = {
  autoLoginWith?: EmailOrPhoneNumber;
}

export const LoginForm = (props: Props) => {
  const dispatch = useAppDispatch();

  const { classes } = useStyles();

  const [
    emailOrPhone,
    setEmailOrPhone,
  ] = useState<EmailOrPhoneNumber>("phoneNumber");

  useEffect(() => {
    if (!props.autoLoginWith) {
      setFocus("email");
      return;
    }
    if (props.autoLoginWith === "email") {
      setEmailOrPhone("email");
    } else if (props.autoLoginWith === "phoneNumber") {
      setEmailOrPhone("phoneNumber");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.autoLoginWith ]);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    control,
    setFocus,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>();

  useFormPersist(STORE_FORM_ACCOUNT, {
    watch: watch,
    setValue: setValue,
    exclude: [
      "firstname",
      "lastname",
      "confirmPassword",
      "exhibition",
    ],
  });

  const debugAutofill = config.debug.autofill && config.debug.enabled;

  const emailInitialValue = debugAutofill ? config.debug.stubData.email : "";

  const passwordInitialValue = debugAutofill ? config.debug.stubData.password : "";

  const [ login ] = useLoginMutation();
  const [ loginOtp ] = useLoginOtpMutation();
  const [ accountExistsByEamil ] = useLazyAccountExistByEmailQuery();
  const [ accountExistsByPhoneNumber ] = useLazyAccountExistByPhoneNumberQuery();

  const email = watch("email");
  const phoneNumber = watch("phoneNumber");
  const password = watch("password");

  const onFormErrors = e => {
    if (e.email) {
      dispatch(toast({
        message: t`Please enter a valid email address`,
        color: "danger",
      }));
    }

    if (e.password) {
      dispatch(toast({
        message: t`Please enter a valid password`,
        color: "danger",
      }));
    }
  };

  const checkRequired = (email:string, phoneNumber:string) => {
    if (
      (emailOrPhone === "email" && !email) ||
      (emailOrPhone === "phoneNumber" && !phoneNumber)
    ) {
      dispatch(toast({
        message: t`Please enter at least one of the following: email or phone number`,
        color: "danger",
      }));
      return false;
    }
    return true;
  };

  const checkAccountExists = async() => {
    if (!checkRequired(email, phoneNumber)) {
      return;
    }

    let query = null;
    if (emailOrPhone === "email") {
      query = await accountExistsByEamil({ data: { email: email } });
    } else {
      query = await accountExistsByPhoneNumber({ data: { phoneNumber: phoneNumber } });
    }

    if (
      query &&
      query.isError
    ) {
      if (query.error.message.includes(PHONE_NUMBER_NOT_VALID_ERROR_MESSAGE)) {
        dispatch(toast({
          message: t`Phone number not valid, please insert a valid phone number.`,
          color: "danger",
        }));
        return;
      }
      if (query.error.message.includes(EMAIL_NOT_VALID_ERROR_MESSAGE)) {
        dispatch(toast({
          message: t`Email not valid, please insert a valid email.`,
          color: "danger",
        }));
        return;
      }
      return;
    }

    if (
      query &&
      query.data === true
    ) {
      setAccountExists(true);
      setFocus("password");
    } else {
      historyController.replace(routes.accountSignup.redirectPath);
    }
  };

  const onSubmit = async(formData: FormData) => {
    if (!checkRequired(formData.email, formData.phoneNumber)) {
      return;
    }
    const accountLoginInput : AccountLoginInputType = {
      email: null,
      phoneNumber: null,
      password: formData.password,
    };
    if (emailOrPhone === "email") {
      accountLoginInput.email = formData.email;
    } else {
      accountLoginInput.phoneNumber = formData.phoneNumber;
    }
    try {
      await login({ data: accountLoginInput }).unwrap();
      dispatch(toastDoneAll());
      historyController.replace(routes.dashboard.redirectPath);
      dispatch(setActiveRoute(routes.dashboard.code));
    } catch (error) {
      if (error.message.includes(PLEASE_CONFIRM_OTP_ERROR_MESSAGE)) {
        setIsOtpModalVisible(true);
        return;
      }
      if (error.message.includes(EMAIL_OR_PASSWORD_ERROR_MESSAGE)) {
        dispatch(toast({
          message: t`Error username or password invalid`,
          color: "danger",
        }));
        return;
      }
      if (error.message.includes(PASSWORD_FORCE_EXPIRED_ERROR_MESSAGE)) {
        dispatch(toast({
          message: t`Error password expired!`,
          color: "danger",
        }));
      }
      return;
    }
  };

  const [
    isOtpModalVisible,
    setIsOtpModalVisible,
  ] = useState<boolean>(false);

  const [
    accountExists,
    setAccountExists,
  ] = useState<boolean | null>(null);

  const verifyOtp = async(otp: string) => {
    const loginFormData: AccountLoginOtpInputType = {
      phoneNumber: phoneNumber,
      password: password,
      otp: otp,
    };
    const loginOtpResult = await loginOtp({ data: loginFormData });
    if (loginOtpResult.hasOwnProperty("error")) {
      return false;
    }
    historyController.replace(routes.dashboard.redirectPath);
    return true;
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handleContinueKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(checkAccountExists)();
    }
  };

  const cancelOtp = () => {
    setIsOtpModalVisible(false);
  };

  const switchEmailOrPhone = () => {
    const newEmailOrPhone = emailOrPhone === "email" ? "phoneNumber" : "email";
    setEmailOrPhone(newEmailOrPhone);
    setAccountExists(null);
    if (newEmailOrPhone === "email") {
      setFocus("email");
    } else {
      setFocus("phoneNumber");
    }
  };

  return <>
    {
      isOtpModalVisible &&
		  <OtpModalComponent
			  onVerify={verifyOtp}
			  onCancel={cancelOtp}
		  />
    }

    <FormUi onSubmit={handleSubmit(onSubmit, onFormErrors)}>
      <>
        <IonSegment
          value={emailOrPhone}
          onIonChange={switchEmailOrPhone}
        >
          <IonSegmentButton value="phoneNumber">
            <IonLabel><Trans>Phone Number</Trans></IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="email">
            <IonLabel><Trans>Email</Trans></IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </>

      <VisibleUi if={emailOrPhone === "email"}>
        <InputUi
          type="email"
          inputMode={"email"}
          isValid={!Boolean(errors.email)}
          id="email"
          onKeyDown={handleContinueKeyDown}
          placeholder={`${ i18n._(/*i18n*/"Email") }`}
          autoComplete={"email"}
          tabIndex={1}
          {...register("email", {
            pattern: {
              value: emailPattern,
              message: t`This is not a valid email`,
            },
            value: emailInitialValue,
          })}
        />
        {
          errors.email &&
		      <FormErrorUi
			      error={t`Please enter a valid email address`}
		      />
        }
      </VisibleUi>

      <VisibleUi if={emailOrPhone === "phoneNumber"}>
        <InputTelUi
          isValid={!Boolean(errors.phoneNumber)}
          id="phoneNumber"
          name={"phoneNumber"}
          placeholder={`${ i18n._(/*i18n*/"Phone number") }`}
          tabIndex={10}
          control={control}
        />
        {
          errors.phoneNumber &&
		      <FormErrorUi
			      error={t`Please enter a valid phone number`}
		      />
        }
      </VisibleUi>

      <VisibleUi if={!accountExists}>
        <IonRow>
          <IonCol>
            <PrimaryPositiveButtonUi
              formType={"button"}
              onClick={checkAccountExists}
              label={t`Continue`}
            />
          </IonCol>
        </IonRow>
      </VisibleUi>

      <VisibleUi if={accountExists}>
        <InputUi
          type="password"
          isValid={!Boolean(errors.password)}
          id="password"
          placeholder={`${ i18n._(/*i18n*/"Password") }`}
          autoComplete={"current-password"}
          tabIndex={2}
          hasTooltip
          hasViewPassword
          onChange={handleKeyDown}
          tooltipMessage={t`Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|`}
          {...register("password", {
            required: true,
            pattern: {
              value: passwordPattern,
              message: t`Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|`,
            },
            value: passwordInitialValue,
          })}
        />
        {
          errors.password &&
          <FormErrorUi error={t`The provided password is invalid`} />
        }
        <IonRow>
          <IonCol>
            <p
              className={classes.forgotPasswordLink}
              onClick={() => {
                historyController.replace(routes.accountForgotPassword.redirectPath);
              }}>
              <Trans>Forgot your password?</Trans>
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {isSubmitting && <PrimaryPositiveButtonUi isLoading />}
            {!isSubmitting && <PrimaryPositiveButtonUi label={t`Log In`} />}
          </IonCol>
        </IonRow>
      </VisibleUi>
    </FormUi>
  </>;
};
